import AuthenticationService from "service/auth/AuthenticationService";
import EnvironmentService from "service/environment/EnvironmentService";
import APIConfig from "service/network/APIConfig";
import APIService from "service/network/APIService";

class PatientsService {
  constructor() {
    this.authenticationService = new AuthenticationService();
    this.environmentService = new EnvironmentService();
    this.apiService = new APIService();
  }

  new(patient, onResponse, onError) {
    let url = APIConfig.patient.new;

    return this.apiService.post(url, patient, onResponse, onError);
  }

  newProfile(patient, onResponse, onError) {
    let url = APIConfig.patient.newProfile;

    return this.apiService.post(url, patient, onResponse, onError);
  }

  get(patientId, onResponse, onError) {
    let url = APIConfig.patient.get.format(patientId);

    return this.apiService.get(url, onResponse, onError);
  }

  getAll(onResponse, onError) {
    let url = APIConfig.patient.getAll;

    return this.apiService.get(url, onResponse, onError);
  }

  getProfileIdsForPhoneNo(data, onResponse, onError) {
    let url = APIConfig.patient.getProfileIds.format(
      data.phone,
      data.cc,
      data.tenantid
    );

    return this.apiService.get(url, onResponse, onError);
  }

  searchFromDoctor(params, onResponse, onError) {
    let url = APIConfig.patient.searchFromDoctor.format(
      params.query,
      params.type,
      params.from,
      params.size
    );
    return this.apiService.get(url, onResponse, onError);
  }
  search(search, onResponse, onError) {
    let url;
    let tenantid = this.authenticationService.getTenantId();
    // search
    if (search.type === 3)
      url = APIConfig.patient.searchPhoneno.format(
        search.query,
        search.type,
        search.paid,
        search.cc,
        tenantid
      );
    else
      url = APIConfig.patient.search.format(
        search.query,
        search.type,
        search.paid,
        tenantid
      );
    return this.apiService.get(url, onResponse, onError);
  }

  getPatient(patientId, onResponse, onError) {
    let url = APIConfig.patient.get.format(patientId);

    return this.apiService.get(url, onResponse, onError);
  }

  updateParams(patientId, patient, onResponse, onError) {
    let url = APIConfig.patient.updateParams + patientId + "/params";

    return this.apiService.put(url, patient, onResponse, onError);
  }

  updateParamGroups(patientId, patient, onResponse, onError) {
    let url = APIConfig.patient.updateParamGroups.format(patientId);

    return this.apiService.put(url, patient, onResponse, onError);
  }

  getTrackingInfo(patientId, doctorId, onResponse, onError) {
    let url = APIConfig.patient.getTrackingInfo.format(patientId, doctorId);

    return this.apiService.get(url, onResponse, onError);
  }

  changeStatus(patientId, patientRequest, onResponse, onError) {
    let url = APIConfig.patient.updateStatus.format(patientId);

    return this.apiService.put(url, patientRequest, onResponse, onError);
  }

  invite(patientId, onResponse, onError) {
    let url = APIConfig.patient.invite.format(patientId);

    return this.apiService.put(url, {}, onResponse, onError);
  }

  updateEmail(body, onResponse, onError) {
    let url = APIConfig.patient.updateEmail;
    return this.apiService.put(url, body, onResponse, onError);
  }
  getPrescription(patientId, onResponse, onError) {
    let url = APIConfig.patient.prescription.format(patientId);
    return this.apiService.get(url, onResponse, onError);
  }

  verifyPrescription(patientId, status, commentType, onResponse, onError) {
    let url = APIConfig.patient.verifyStatus.format(patientId);
    return this.apiService.put(
      url,
      {
        status: status,
        commentType: commentType,
      },
      onResponse,
      onError
    );
  }
  getReferrer(patientId, onResponse, onError) {
    let url = APIConfig.patient.referrer.format(patientId);
    return this.apiService.get(url, onResponse, onError);
  }
  updateReferrer(patientId, docCode, onResponse, onError) {
    let url = APIConfig.patient.referrer.format(patientId);

    return this.apiService.put(
      url,
      { doctorCode: docCode },
      onResponse,
      onError
    );
  }
  getPrimaryTherapyInfo(patientId, onResponse, onError) {
    let url =
      "https://services.prod.zyla.in/v2/pa/assessments/" +
      patientId +
      "/primary";
    if (!this.environmentService.isProduction()) {
      url = APIConfig.patient.primaryTherapy.format(patientId);
    }

    return this.apiService.get(url, onResponse, onError);
  }
  getTherapyBasedLabtest = (primaryTherapy, onResponse, onError) => {
    let url = APIConfig.patient.therapyLabTest.format(primaryTherapy);
    return this.apiService.get(url, onResponse, onError);
  };
  getTherepyList(onResponse, onError) {
    let url = APIConfig.patient.therapyQuestions;
    return this.apiService.get(url, onResponse, onError);
  }
  changeTherapy(id, body, onResponse, onError) {
    let url = APIConfig.patient.changeTherapy.format(id);
    return this.apiService.post(url, body, onResponse, onError);
  }
  fetchLatestVitals(id, onResponse, onError) {
    let url = APIConfig.vital.latestVital.format(id);
    return this.apiService.get(url, onResponse, onError);
  }

  searchPatientProgram(body, onResponse, onError) {
    let url = APIConfig.patientProgram.patientSearch;
    return this.apiService.post(url, body, onResponse, onError);
  }
  fetchFollowUp(patientId, onResponse, onError) {
    var size = 1;
    var pageNo = 1;
    let url = APIConfig.followUp.get.format(patientId, pageNo, size);
    return this.apiService.get(url, onResponse, onError);
  }
  addFollowUp(body, onResponse, onError) {
    let url = APIConfig.followUp.add;
    return this.apiService.post(url, body, onResponse, onError);
  }
  fetchLanguage(onResponse, onError) {
    let url = APIConfig.language.get;
    return this.apiService.get(url, onResponse, onError);
  }
  updateLanguage(id, language, onResponse, onError) {
    let url = APIConfig.language.add.format(id);

    return this.apiService.post(url, language, onResponse, onError);
  }
  fetchPatientLanguage(id, onResponse, onError) {
    let url = APIConfig.language.getPatientLanguage.format(id);
    return this.apiService.get(url, onResponse, onError);
  }
  fetchAlternatePhoneNo(id, onResponse, onError) {
    let url = APIConfig.alternatePhoneNo.get.format(id);
    return this.apiService.get(url, onResponse, onError);
  }
  addNumber(id, requestBody, onResponse, onError) {
    let url = APIConfig.alternatePhoneNo.add.format(id);
    return this.apiService.post(url, requestBody, onResponse, onError);
  }
  updateNumber(id, noId, requestBody, onResponse, onError) {
    let url = APIConfig.alternatePhoneNo.update.format(id, noId);
    return this.apiService.put(url, requestBody, onResponse, onError);
  }
  getAthenaInsight = (url, id, maxId, minId, onResponse, onError) => {
    let myUrl;
    if (url) {
      myUrl = url;
    } else {
      myUrl = APIConfig.patient.athenaInsight.getInsight.format(id, 5, "", "");
    }

    return this.apiService.get(myUrl, onResponse, onError);
  };
  getLatestSettings = (id, onResponse, onError) => {
    const url = APIConfig.patient.getSettings.format(id);
    return this.apiService.get(url, onResponse, onError);
  };
  patchUserSettings = (id, params, onResponse, onError) => {
    const url = APIConfig.patient.patchSettings.format(id);

    return this.apiService.patch(url, params, onResponse, onError);
  };
  updateAthenaStatus = (pid, insightId, params, onResponse, onError) => {
    let url = APIConfig.patient.athenaInsight.updateInsight.format(
      pid,
      insightId
    );
    return this.apiService.put(url, params, onResponse, onError);
  };
  getBlinkingStatus = (id, onResponse, onError) => {
    let url = APIConfig.patient.athenaInsight.shouldBlink.format(id);
    return this.apiService.get(url, onResponse, onError);
  };
  clientCode(onResponse, onError) {
    let url = APIConfig.whitelisting.getAll;
    return this.apiService.get(url, onResponse, onError);
  }
  searchPhoneNo(search, onResponse, onError) {
    let url;
    url = APIConfig.patient.searchPhoneno3.format(search.phoneno, search.cc);
    return this.apiService.get(url, onResponse, onError);
  }
  searchProfileExist(search, onResponse, onError) {
    let url;
    url = APIConfig.patient.searchProfileExist.format(
      search.phoneno,
      search.cc,
      search.firstName,
      search.lastName
    );
    return this.apiService.get(url, onResponse, onError);
  }

  getStepDataByDay = (patientID, fromDay, toDay, onResponse, onError) => {
    let url = APIConfig.device.getSteps.format(patientID, fromDay, toDay);
    return this.apiService.get(url, onResponse, onError);
  };
  getSleepDataByDay = (patientID, fromDay, toDay, onResponse, onError) => {
    let url = APIConfig.device.getSleep.format(patientID, fromDay, toDay);
    return this.apiService.get(url, onResponse, onError);
  };
  getTrackerData(patientID, fromDay, toDay, onResponse, onError) {
    let url = APIConfig.device.getTrackerData.format(patientID, fromDay, toDay);
    return this.apiService.get(url, onResponse, onError);
  }
  //get all primary address
  getAllAddressPrimary = (onResponse, onError) => {
    let url = APIConfig.familyPlan.primaryAddress;
    return this.apiService.get(url, onResponse, onError);
  };

  //get all relations
  getAllRelations = (onResponse, onError) => {
    let url = APIConfig.familyPlan.getAllRelation;
    return this.apiService.get(url, onResponse, onError);
  };
  //generate and save document token
  getAndSaveDocumentToken = (onResponse, onError) => {
    let url = APIConfig.documentApis.tokenGenerate;
    return this.apiService.get(url, onResponse, onError);
  };

  fetchLatestProfile(patientId, onResponse, onError) {
    let url = APIConfig.user.latestUserProfile.format(patientId);
    return this.apiService.get(url, onResponse, onError);
  }
  fetchDeliveryDate(id, onResponse, onError) {
    let url = APIConfig.user.deliveryDate.format(id);
    return this.apiService.get(url, onResponse, onError);
  }
}

export default PatientsService;
